import React from 'react';
import Navbar from '../navbar/Navbar';
import Descpachante from '../despachante/Despachante';
import Footer from '../footer/Footer';
import Despachante from '../despachante/Despachante';


function DespaPage () {
    return (
        <>
        <Navbar />
        <Despachante />
        <Footer />
        </>
    )
}

export default DespaPage;