import React from 'react';
import Navbar from '../navbar/Navbar';
import Curso1 from '../cursos/Curso1';
import Footer from '../footer/Footer';



function Curso1Page () {
    return (
        <>
        <Navbar />
        <Curso1 />
        <Footer />
        </>
    )
}

export default Curso1Page;