import React from 'react';
import Navbar from '../navbar/Navbar';
import Sobre from '../sobre/Sobre';
import Footer from '../footer/Footer';


function SobrePage () {
    return (
        <>
        <Navbar />
        <Sobre />
        <Footer />
        </>
    )
}

export default SobrePage;