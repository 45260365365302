import React from 'react';
import Navbar from '../navbar/Navbar';
import Curso2 from '../cursos/Curso2';
import Footer from '../footer/Footer';



function Curso2Page () {
    return (
        <>
        <Navbar />
        <Curso2 />
        <Footer />
        </>
    )
}

export default Curso2Page;