import React from 'react';
import Navbar from '../navbar/Navbar';
import Curso3 from '../cursos/Curso3';
import Footer from '../footer/Footer';



function Curso3Page () {
    return (
        <>
        <Navbar />
        <Curso3 />
        <Footer />
        </>
    )
}

export default Curso3Page;